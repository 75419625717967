@import "src/styles/prepend"; .authentication {
  position: relative;
  min-height: var(--app-height);
  padding: $layout-unit * 5;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
  }

  // .authentication__left-content-wrapper
  &__left-content-wrapper {
    position: relative;
    
    @include media-breakpoint-up(md) {
      // .authentication__left-content-wrapper--centered
      &--centered {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: $layout-unit * 10;
        margin-bottom: $layout-unit * 10;
      }
    }
  }

  // .authentication__left-content
  &__left-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  // .authentication__logo
  &__logo {
    padding-bottom: $layout-unit * 5;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      padding-left: $layout-unit * 10;
    }
  }

  // .authentication__right-content
  &__right-content {
    position: relative;
    height: 265px;
    width: 100%;
    margin-bottom: $layout-unit * 8;
    border-radius: $layout-unit * 5;
    background-color: $color-blue-500;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      position: sticky;
      top: 20px;
      height: calc(var(--app-height) - 40px);
      margin-bottom: 0;
    }
  }
}
