@import "src/styles/prepend"; .login {
  position: static;

  // .login__circle1
  &__circle1 {
    position: absolute;
    left: -190px;
    bottom: 170px;
  }

  // .login__circle2
  &__circle2 {
    position: absolute;
    left: 100px;
    bottom: 2px;
  }

  // .login__circle3
  &__circle3 {
    position: absolute;
    left: 235px;
    bottom: 130px;
  }

  // .login__circle4
  &__circle4 {
    position: absolute;
    left: 280px;
    top: -15px;
  }

  // .login__circle5
  &__circle5 {
    position: absolute;
    right: -70px;
    top: $layout-unit * 4;
  }

  // .login__circle6
  &__circle6 {
    position: absolute;
    left: -$layout-unit * 5;
    top: 50%;
    transform: translateY(-50%);
  }

  // .login__triangle
  &__triangle {
    position: absolute;
    left: 50px;
    bottom: 0px;
  }

  // .login__platform-image
  &__platform-image {
    position: absolute;
    top: 60%;
    left: $layout-unit * 20;
    height: 80%;
    width: 100%;
    transform: translateY(-60%);

    @include media-breakpoint-down(md) {
      left: $layout-unit * 10;
      max-height: 220px;
    }
  }
}
