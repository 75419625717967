@import "src/styles/prepend"; .input {
  position: relative;
  display: inline-block;
  transition: opacity .3s ease-in-out;

  // .input__color-input
  &__color-input {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 200%;
    height: 200%;
  }

  // .input__color-input-wrapper
  &__color-input-wrapper {
    position: relative;
    width: $layout-unit * 4;
    aspect-ratio: 1/1;
    border-radius: $layout-unit;
    overflow: hidden;
  }

  // .input__description
  &__description {
    display: block;
    color: $color-grey-500;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small-paragraph;
    margin-top: $layout-unit * 2;
    user-select: none;

    // .input__description--error
    &--error {
      color: $color-error;
    }
  }

  // .input__feedback-loader
  &__feedback-loader {
    width: $layout-unit * 3;
    height: $layout-unit * 3;
    // This margin is necessary to keep the loader aligned with the text
    margin-top: 5px;
  }

  // .input__input
  &__input {
    all: unset;
    border-radius: $layout-unit;
    box-shadow: inset 0 0 0 1px $color-grey-300;
    box-sizing: border-box;
    color: $color-blue-500;
    outline: none;
    padding: $layout-unit * 2.5 $layout-unit * 4;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;
    width: 100%;

    // .input__input:active:not(.input:disabled):not(.button--error),
    // .input__input:focus:not(.input:disabled):not(.button--error),
    // .input__input:hover:not(.input:disabled):not(.button--error),
    &:active:not(:disabled):not(&--error),
    &:focus:not(:disabled):not(&--error),
    &:hover:not(:disabled):not(&--error) {
      box-shadow: inset 0 0 0 1px $color-blue-500;
    }

    // .input__input:disabled
    &:disabled {
      background-color: $color-grey-200;
    }

    // .input__input::placeholder
    &::placeholder {
      color: $color-grey-400;
    }

    // .input__input--error
    &--error {
      box-shadow: inset 0 0 0 1px $color-error;
    }

    // .input__input--flex
    &--flex {
      display: flex;
      align-items: center;
      gap: $layout-unit * 2;
    }

    // .input__input--extra-padding-left
    &--extra-padding-left {
      padding-left: 13 * $layout-unit;
    }

    // .input__input--extra-padding-left-small
    &--extra-padding-left-small {
      padding-left: 11 * $layout-unit;
    }

    // .input__input--extra-padding-right
    &--extra-padding-right {
      padding-right: 13 * $layout-unit;
    }

    // .input__input--extra-padding-right-small
    &--extra-padding-right-small {
      padding-right: 11 * $layout-unit;
    }

    // .input__input--textarea
    &--textarea {
      resize: none;
    }
  }

  // .input__input-wrapper
  &__input-wrapper {
    position: relative;
  }

  // .input__info-icon
  &__info-icon {
    color: $color-error;
    margin-right: $layout-unit;
  }

  // .input__message
  &__message {
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small-paragraph;
    user-select: none;
    
    // .input__message--success
    &--success {
      color: $color-success;
    }
  
    // .input__message--warning
    &--warning {
      color: $color-error;
    }
  }

  // .input__message-icon
  &__message-icon {
    margin-right: $layout-unit;
    
    // .input__message-icon--success
    &--success {
      color: $color-success;
    }

    // .input__message-icon--warning
    &--warning {
      color: $color-error;
    }
  }

  // .input__label
  &__label {
    display: block;
    margin-bottom: $layout-unit * 2;
    user-select: none;
  }

  // .input__label-required-mark
  &__label-required-mark {
    color: $color-blue-500;
    transition: .3s color ease-in-out;

    // .input__label-required-mark--error
    &--error {
      color: $color-error;
    }
  }

  // .input__slot
  &__slot {
    position: absolute;
    top: 50%;
    right: $layout-unit * 4;
    display: flex;
    height: fit-content;
    width: fit-content;
    transform: translateY(-50%);
    outline: none;

    // .input__slot--left
    &--left {
      right: initial;
      left: $layout-unit * 4;

      // .input__slot--left.input__slot--small-margin
      &.input__slot--small-margin {
       left: $layout-unit * 3;
      }

      // .input__slot--left.input__slot--no-margin
      &.input__slot--no-margin {
        left: 0;
      }
    }

    // .input__slot--no-margin
    &--no-margin {
      right: 0;
    }

    // .input__slot--small-margin
    &--small-margin {
      right: $layout-unit * 3;
    }
  }

  // .input--block
  &--block {
    display: block;
  }

  // .input--disabled
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
