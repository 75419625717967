@import "src/styles/prepend"; .login-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  // .login-form__checkbox
  &__checkbox {
    margin-bottom: $layout-unit * 5;
    margin-top: $layout-unit * 3;
  }

  // .login-form__create-account-link
  &__create-account-link {
    margin-left: $layout-unit * 2;
  }

  // .login-form__create-account-text
  &__create-account-text {
    display: flex;
    justify-content: center;
    margin-top: $layout-unit * 4.5;
    color: $color-grey-500;
  }

  // .login-form__divider
  &__divider {
    display: flex;
    align-items: center;
  }

  // .login-form__divider-line
  &__divider-line {
    height: 1px;
    background-color: $color-grey-400;
    border: none;
    width: 100%;
  }

  // .login-form__divider-text
  &__divider-text {
    display: inline;
    color: $color-grey-400;
    margin-left: $layout-unit * 2.5;
    margin-right: $layout-unit * 2.5;
  }

  // .login-form__error-box
  &__error-box {
    margin-bottom: $layout-unit * 5;
  }

  // .login-form__forgot-password-link
  &__forgot-password-link {
    margin-bottom: $layout-unit * 6;
    margin-top: $layout-unit * 5;
  }

  // .login-form__google-button
  &__google-button {
    width: 100%;
    margin-top: $layout-unit * 6;
  }

  // .login-form__input
  &__input {
    width: 100%;

    // .login-form__input:not(:first-og-type)
    &:not(:first-of-type) {
      margin-top: $layout-unit * 5;
    }
  }

  // .login-form__loader
  &__loader {
    display: flex;
    color: $color-white;
    padding: $layout-unit * 4;
    border-radius: 50%;
    background-color: $color-primary-500;
  }

  // .login-form__loader-and-submit-button
  &__loader-and-submit-button {
    margin-top: $layout-unit * 5;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  // .login-form__loader-wrapper
  &__loader-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    // .login-form__loader-wrapper--visible
    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }

  // .login-form__login-alternatives
  &__login-alternatives {
    text-align: center;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    // .login-form__login-alternatives--hidden
    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  // .login-form__submit-button
  &__submit-button {
    opacity: 1;
    visibility: visible;
    transition: background-color .3s ease-in-out, opacity .3s ease-in-out, outline .3s ease-in-out, visibility .3s ease-in-out;

    // .login-form__submit-button--hidden
    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  // .login-form__title
  &__title {
    margin-bottom: $layout-unit * 6;

    @include media-breakpoint-up(md) {
      margin-bottom: $layout-unit * 8;
    }
  }
  
  // .login-form__wrapper
  &__wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    transition: transform .3s ease-in-out;
    z-index: 1;

    @include media-breakpoint-up(md) {
      // Design value
      width: 340px;

      // .login-form__wrapper--submitting
      &--submitting {
        transform: translateY(90px);
      }
    }
  }
}
