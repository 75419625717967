@import "src/styles/prepend"; .error-box {
  color: $color-error-500;
  font-size: $font-size-extra-small;
  padding: $layout-unit * 2 $layout-unit * 3;
  border: 1px solid $color-error-500;
  border-radius: $layout-unit;
  background: mix($color-white, $color-warning, 90%);

  // .error-box__link
  &__link {
    position: relative;

    // .error-box__link::after
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: $color-error-500;
    }
  }
}
